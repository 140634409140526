<template>
  <div class="modal ready-modal">
    <div class="overlay" @click="$parent.closeDeactivateModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeDeactivateModal" src="./../assets/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title small">
                {{$t('Are you sure you want to deactivate this product?')}}
                <br/>
                {{$t('This product will not be available for purchase by other users')}}
              </div>
              <div class="buttons">
                <button class="button blue" @click="$parent.closeDeactivateModal">
                  <span>{{$t('Cancel')}}</span>
                </button>
                <button class="button" @click="deactivate">
                  <span>{{$t('Deactivate')}}</span>
                </button>
              </div>
              <transition name="fade">
                <div class="desc error-desc red" v-if="$parent.error">{{$parent.error}}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'DeactivateModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  
  methods: {
		deactivate() {
			this.$http.post(process.env.VUE_APP_API + 'user/media/' + this.$parent.itemToDeactivate + '/delete')
      .then((res) => {
        if (res.data.status == "OK") {
          this.$parent.getCreatedHistory();
          this.$parent.closeDeactivateModal();
        } else {
          this.$parent.closeDeactivateModal();
          this.$emit('setNewError', res.data.message)
        }
      })
      .catch((res) => {
        this.$parent.closeDeactivateModal();
        this.$emit('setNewError', res.response.data.message)
      })
		}
  }
}
</script>