<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <img v-if="productPageItem.product_type == 'image'" :src="$parent.imgDomain + productPageItem.doc_url" class="img"/>
                    <div v-else class="doc-img">
                      <img src="./../assets/doc.svg" class="img"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text">
                <div class="text-wrapper">
                  <div class="top">
                    <div class="row">
                      <div class="desc">{{$t('Name')}}</div>
                      <div class="title">
                        <b>{{productPageItem.title}}</b>
                      </div>

                    </div>
                    <div class="row" v-if="productPageItem.members_only == 1">
                      <div  class="title title-members">
                        <img src="./../assets/member.svg" class="img"/>
                        {{$t('MEMBERS')}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="desc">{{$t('Description')}}</div>
                      <div class="desc" v-html="productPageItem.description"></div>
                    </div>
                    <div class="row tags-row">
                      <div class="desc">{{$t('Keywords')}}</div>
                      <div class="tags">
                        <router-link v-for="tag in productPageItem.tags" :key="tag.id" :to="productPageItem.product_type === 'doc' ? `/product-list/texts?filter[tag]=${tag.name}` : `/product-list/images?filter[tag]=${tag.name}` " class="button tags__item">
                          {{ $t(tag.name) }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="bottom-wrapper">
                      <div class="price">
                        <div class="price-title desc">{{$t('Price')}}:</div>
                        <div class="price-value title">
                          {{productPageItem.price}}
                          <span class="currency">{{$t(currency)}}</span>
                        </div>
                      </div>
                      <button class="button" @click="addToCart(productPageItem,productPageItem.id)">
                        <span v-if="$parent.addToCartChosenItem == $parent.productPageItem.id">{{ $t('Added') }}</span>
                        <span v-if="$parent.addToCartChosenItem != $parent.productPageItem.id">{{ $t('Add to cart') }}</span>
                      </button>
                      <transition name="slide">
                        <div v-if="$parent.error" class="error-desc desc red">{{$t($parent.error)}}</div>
                      </transition>
                    </div>
                  </div>
                  <div class="desc" v-if="productPageItem.product_type == 'image'">{{$t('This is only a preview and not a real representation of the picture. After purchasing the product, you will be able to download it in the 1080x1080 quality, in format of PNG, JPEG or PDF and without watermarks')}}</div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'ProductPage',
  props: ['currency', 'productPageItem'],
  components: {
  },
  data: function() {
    return {
      tag: '',
    }
  },
	watch: {
    currency: function(newVal) {
      console.log(newVal)
      const id = this.$route.params.id;
      this.$emit('getProductPageContent', id);
    },
    categories: function(newVal) {
      let tag = newVal.find(cat => cat.id ==  this.productPageItem.category_id);
			this.tag = tag.title;
    },
    productPageItem: function(newVal) {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        let categories = res.data.payload;
        let tag;
        tag = categories.find(cat => cat.id ==  newVal.category_id);
        this.tag = tag.title;
        
      })
      .catch(() => {
        
      })
    }
	},
  created() {
    
  },
  computed: {
    keywords() {
      return this.productPageItem.keywords.split(",");
    },
  },
  
  mounted() {
    this.$emit('getProductPageContent',this.$route.params.id);
    this.$http.get(process.env.VUE_APP_API + 'categories')
		.then((res) => {
			let categories = res.data.payload;
			let tag = categories.find(cat => cat.id ==  this.productPageItem.category_id);
			this.tag = tag.title;
		})
		.catch(() => {
			
		})
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
  }
}
</script>