<template>
  <div class="modal withdraw-modal success-create-modal">
    <div class="overlay" @click="$parent.closeSuccessCreateModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeSuccessCreateModal" src="./../assets/close.svg"/>
      <div class="container">
        <img class="img" src="./../assets/success.svg"/>
        <div class="title">{{ $t("Your offer successfully sent") }}</div>
        <div class="desc">
          {{ $t("We will contact you by e-mail within 3 business days") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SuccessCreateModal',
  props: [],
  components: {
   
  },
  data: function() {
    return {
     
    }
  },
  methods: {

  }
}
</script>