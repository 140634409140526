<template>
  <div class="modal ready-modal">
    <div class="overlay" @click="$parent.closeReadyModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeReadyModal" src="./../assets/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title small">{{ $t('Are you sure?') }}</div>
              <div class="buttons">
                <button class="button blue" @click="$parent.closeReadyModal">
                  <span>{{ $t('Cancel') }}</span>
                </button>
                <button :class="['button', { 'disabled': $parent.addToCartBtnLoader }]" @click="addToCart">
                  <span>{{ $t('Yes') }}</span>
                </button>
              </div>
              <transition name="fade">
                <div class="desc error-desc red" v-if="$parent.error">{{ $parent.error }}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'ReadyModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  
  methods: {
		addToCart() {
			this.$emit('addToCart')
		}
  }
}
</script>