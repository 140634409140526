<template>
  <main class="main cart-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="cart-top">
            <div class="cart-top__items-count">
              <div class="text">
                <div class='icon button blue'>
                  <img class="img" src="./../assets/cart.svg"/>
                </div>
                <div class="small desc">
                  <b>CART</b>
                </div>
                <div class="desc small">
                  ({{ $parent.cartContents.length  }} {{  $t('Items') }})
                </div>
              </div>
              <div class="desc small link" @click="crealCart()">
                <b>{{ $t('Clear cart') }}</b>
              </div>
            </div>
            <div class="cart-top__total">
              <div class="desc small gray">
                {{ $t('Total:') }}
              </div>
              <div class="title small">
                <b>{{totalSum}} <span class="currency">{{$parent.currency}}</span></b>
              </div>
            </div>
          </div>
          <div class="desc big empty-desc" v-if="!cartContents.length">
            {{$t('Cart is empty!')}}
          </div>
          <div class="right" v-if="cartContents.length">
            <div class="title small">
              <b>{{$t('Billing information')}}</b>
            </div>
            <div class="wrapper">
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t('First name')}}:</div>
                <input type="text" :placeholder="$t('First name')" v-model="name"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t('Last name')}}:</div>
                <input type="text" :placeholder="$t('Last name')" v-model="surname"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t('Phone')}}:</div>
                <input type="tel" :placeholder="$t('Phone')" v-model="phone"/>
              </div>
              <div class="input-container" v-if="countryOptions && countryOptions.length">
                <div class="desc"><span class="red">*</span>{{$t('Country')}}:</div>
                <select v-model="country">
                  <option value="" selected disabled>{{$t('Country')}}</option>
                  <option v-for="item in countryOptions" :key="item.id">{{$t(item.title)}}</option>
                </select>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t('City')}}:</div>
                <input type="text" :placeholder="$t('City')" v-model="city"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t('Address')}}:</div>
                <input type="text" :placeholder="$t('Address')" v-model="address"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t('Post Code')}}:</div>
                <input type="text" :placeholder="$t('Post Code')" v-model="postCode"/>
              </div>
              <div class="input-container">
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms"/>
                      <div class="checkbox"></div>
                      <span class="title">{{$t('I agree with')}} </span>
                      <a @click="$parent.goToPage('privacy')" class="title">{{$t('privacy policy')}} </a>
                      <span class="title">{{$t('and')}} </span>
                      <a @click="$parent.goToPage('terms')" class="title">{{$t('terms and conditions')}}</a>
                    </div>
                  </label>
                </div>
              </div>
              <button :class="['button blue', {'disabled': !requiredFieldsAreFilled}]" @click="submit">
                <span>{{$t('Checkout')}}</span>
              </button>
              <transition name="fade">
                <div :class="['desc', {'error-desc red': redAmount}]">{{$t('Minimum checkout amount is 5')}} {{$parent.currency}}</div>
              </transition>
              <transition name="fade">
                <div v-if="$parent.error" class="desc error-desc red">{{$t($parent.error)}}</div>
              </transition>
            </div>
          </div>
          <div class="left" v-if="cartContents.length">
            <div class="product-table">
              <div class="table">
                <table>
                  <thead>
                  <tr>
                    <th>
                      <div class="td-wrapper">
                        {{$t('Item')}}
                      </div>
                    </th>
                    <th>
                      <div class="td-wrapper">
                        {{$t('Name')}}
                      </div>
                    </th>
                    <th>
                      <div class="td-wrapper">
                        {{$t('Price')}}
                      </div>
                    </th>
                    <th>
                      <div class="td-wrapper">

                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, i) in cartContents" :key="i">
                    <td>
                      <div class="td-wrapper">
                        <div class="preview">
                          <div v-if="item.item.product_type == 'doc'" class="doc-img">
                            <img src='./../assets/doc.svg' class="img"/>
                          </div>
                          <img v-else :src="imgDomain + item.item.doc_url" class="img"/>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="td-wrapper">
                        {{item.item.title}}
                      </div>
                    </td>
                    <td>
                      <div class="td-wrapper">
                        <div class="price title small">
                          <b>{{item.item.price}} <span class="currency">{{$parent.currency}}</span></b>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="td-wrapper">
                        <div class="delete" @click="removeFromCart(item)">
                          <img src="./../assets/delete.svg" class="img"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>

export default {
  name: 'Cart',
  props: [],
  components: {
  },
  data: function() {
    return {
      imgDomain: '',
      totalSum: '',
      cartContents: '',
      countryOptions: [],
      countryOptionsId: [],
      name: '',
      surname: '',
      phone: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      redAmount: false
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getCartContents();
    this.getCounties();
    this.getProfile();
  },
  watch: {
    country: function() {
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.country === this.countryOptions[i].title) {
          this.countryId = this.countryOptions[i].id
          break;
        }
      }
      this.countryId
    },
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.country && 
        this.city && this.address  
        && this.postCode && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        console.log(res)
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.email = res.data.email;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
        this.countryOptions = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    submit() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "country": this.countryId,
        "city": this.city,
        "address": this.address,
        "postCode": this.postCode
      }
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
      .then((res) => {
          console.log(res);
          window.location = res.data.redirect_url;
      })
      .catch((res) => {
        if (res.response.data.status == 'MIN_AMOUNT_ERROR') {
          this.redAmount = true;
        } else {
          this.redAmount = false;
          this.$emit('setError', res.response.data.message)
        }
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    crealCart() {
      this.$http.post(process.env.VUE_APP_API + 'cart/clear')
      .then((res) => {
        this.getCartContents();
        this.$parent.getCartContents();
        this.cartContents = res.data.cart;
        this.totalSum = res.data.cart.totalAmount
      })
      .catch((res) => {
        console.log(res);
      })
    },
    removeFromCart(item) {
      let param = {
        'id' : item.item_id
      } 
      this.$http.post(process.env.VUE_APP_API + 'cart/remove', param)
      .then((res) => {
        this.$parent.getCartContents();
        this.cartContents = res.data.cart.payload;
        this.totalSum = res.data.cart.totalAmount;
      })
      .catch((res) => {
        console.log(res);
      })
    },
    getCartContents() {
      this.$http.get(process.env.VUE_APP_API + 'cart/contents')
      .then((res) => {
        this.cartContents = res.data.cart.payload;
        this.totalSum = res.data.cart.totalAmount
      })
      .catch((res) => {
        console.log(res);
      })
    },
    toOrder() {
      this.$router.push({ path: '/order' })
    }
  }
}
</script>