<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <img src="./../assets/heroBg.png" class="heroBg"/>
        <img src="./../assets/heroBg3.png" class="heroBg3"/>
        <div class="wrapper">
          <img src="./../assets/cooler.svg" class="cooler"/>
          <div class="item">
            <div class="text">
              <div class="title large">{{$t('TRANSFORM YOUR VISION')}}</div>
              <div class="desc kanit big">
                {{$t('AI-GENERATED IMAGES AND PROMPTS FOR EFFORTLESS CREATIVITY')}}
              </div>
            </div>
            <div class="img-container">
              <img src="./../assets/hero.png" class="img"/>
              <img src="./../assets/sphere1.png" class="sphere1"/>
              <img src="./../assets/sphere2.png" class="sphere2"/>
              <img src="./../assets/sphere3.png" class="sphere3"/>
            </div>
          </div>
          <div class="item">
            <img src="./../assets/heroBg2.png" class="heroBg2"/>
            <div class="img-container">
              <img src="./../assets/art1.png" class="art1"/>
              <img src="./../assets/art2.png" class="art2"/>
              <img src="./../assets/ring.png" class="ring"/>
              <img src="./../assets/rectangle.png" class="rectangle"/>
              <img src="./../assets/sphere4.png" class="sphere4"/>
            </div>
            <div class="text">
              <div class="desc semibold kanit big">
                {{$t('DISCOVER A WORLD')}}
              </div>
              <div class="title big">
                {{$t('WHERE IMAGINATION MEETS INNOVATION')}}
              </div>
              <div class="desc">
                {{$t('At Artifium, we specialize in providing cutting-edge AI-generated images and texts that empower your creativity. Whether you\'re a designer, marketer, writer, or just someone with a passion for unique content, our platform is designed to deliver high-quality, customizable assets tailored to your needs.')}}
              </div>
              <div class="button" @click="explore">{{$t('Explore')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section transform-vision-section">
        <div class="wrapper">
          <div class="desc semibold kanit big">
            {{$t('AI-GENERATED IMAGES')}}
          </div>
          <div class="title big">
            {{$t('TRANSFORM YOUR VISION...')}}
          </div>
          <div class="desc">
            ...into stunning visuals with our AI-powered image generator. From realistic portraits to abstract designs, our technology creates art that resonates with your audience. With a wide array of styles and themes, you can find the perfect image for your project in just a few clicks.
          </div>
        </div>
      </div>
      <div class="section explore-section">
        <div class="wrapper">
          <div class="flex">
            <div class="title medium">
              {{$t('EXPLORE OUR AI-GENERATED CONTENT CATEGORIES')}}
            </div>
            <div class="desc">
              {{$t('At Artifium, we offer a diverse range of AI-generated images and texts, carefully categorized to suit all your creative needs. Whether you\'re looking for eye-catching visuals, compelling narratives, or unique design elements, our categories make it easy to find exactly what you\'re looking for.')}}
            </div>
          </div>
          <div class="flex">
            <img src="./../assets/sphere5.png" class="sphere5"/>
            <img src="./../assets/sphere6.png" class="sphere6"/>
            <img src="./../assets/sphere7.png" class="sphere7"/>
            <img src="./../assets/rectangle2.png" class="rectangle2"/>
            <img src="./../assets/bowl.png" class="bowl"/>

            <img src="./../assets/ring2.png" class="ring2"/>
            <img src="./../assets/bowl2.png" class="bowl2"/>
            <img src="./../assets/quiz.png" class="quiz"/>
            <img src="./../assets/cube.png" class="cube"/>

            <div class="card">
              <div class="desc kanit big">
                {{$t('AI-GENERATED IMAGES')}}
              </div>
              <div class="desc kanit medium">
                {{$t('ABSTRACT & MODERN ART')}}
              </div>
              <div class="desc">
                {{$t('Dive into a collection of vibrant and imaginative pieces perfect for digital art, branding, and more.')}}
              </div>
              <div class="desc kanit medium">
                {{$t('NATURE & LANDSCAPES')}}
              </div>
              <div class="desc">
                {{$t('Explore stunning, AI-created scenes that capture the beauty of the natural world.')}}
              </div>
              <div class="desc kanit medium">
                {{$t('PORTRAITS & PEOPLE')}}
              </div>
              <div class="desc">
                {{$t('From realistic faces to artistic interpretations, find the perfect portrait for your project.')}}
              </div>
              <div class="desc kanit medium">
                {{$t('FANTASY & SCI-FI')}}
              </div>
              <div class="desc">
                {{$t('Enter a world of fantasy with AI-generated images that bring the extraordinary to life.')}}
              </div>
              <div class="desc kanit medium">
                {{$t('BUSINESS & TECHNOLOGY')}}
              </div>
              <div class="desc">
                {{$t('Professional visuals designed to enhance your presentations, websites, and marketing materials.')}}
              </div>
            </div>
            <div class="card">
              <div class="desc kanit big">
                {{$t('AI-GENERATED PROMPTS')}}
              </div>
              <div class="desc kanit medium">
                {{$t('CREATIVE WRITING PROMPTS')}}
              </div>
              <div class="desc">
                {{$t('Ignite your imagination with prompts that inspire stories, poems, and creative narratives across genres.')}}
              </div>
              <div class="desc kanit medium">
                {{$t('MARKETING & ADVERTISING IDEAS')}}
              </div>
              <div class="desc">
                {{$t('Generate fresh ideas for your next campaign, from catchy slogans to full-fledged ad concepts that resonate with your audience.')}}
              </div>
              <div class="desc kanit medium">
                {{$t('DESIGN INSPIRATION PROMPTS')}}
              </div>
              <div class="desc">
                {{$t('Kickstart your design projects with prompts that offer unique concepts and themes tailored to various industries.')}}
              </div>
              <div class="desc kanit medium">
                {{$t('BUSINESS & PRODUCT DEVELOPMENT')}}
              </div>
              <div class="desc">
                {{$t('Explore prompts that help brainstorm new product ideas, business strategies, and innovative solutions for your enterprise.')}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section cta-section">
        <div class="wrapper">
          <div class="card">
            <div class="title big">
              {{$t('EXPLORE OUR COLLECTION')}}
            </div>
            <div class="desc small">
              {{$t('today and see how Artifium can transform the way you create.')}}
            </div>
            <div class="button" @click="explore">{{$t('Explore')}}</div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>


export default {
  name: 'Home',
  props: ['currency'],
  components: {
    
  },
  data: function() {
    return {
      imgDomain: '',
      categories: [],
      productList: [],
      activeType: 'image'
    }
  },
  mounted() {
    this.getProducts();
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categories = res.data.payload;
      })
      .catch(() => {
         
      })
  },
  methods: {
    explore(type) {
      let typeOptions;
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        typeOptions = res.data.payload.filter(item => item.parent_id === null);
        console.log(typeOptions[0])
        if (type == 'doc') {
          this.$emit('chooseType', typeOptions[1]);
        } else {
          this.$emit('chooseType', typeOptions[0]);
        }
        
      })
      .catch(() => {
          
      })
    },
    changeTab(type) {
      this.activeType = type;
      this.getProducts();
    },
    getProducts() {
      this.productList = [];
			this.$http.get(process.env.VUE_APP_API + 'products/favorites')
			.then((res) => {
        this.productList = res.data.payload.filter(item => (item.product_type === this.activeType));
			})
			.catch(() => {
				
			})
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openReadyModal() {
			this.$parent.openReadyModal();
			this.$parent.setReadyPack();
		},
  
  }
}
</script>