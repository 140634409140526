import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from './pages/PageNotFound.vue'
import Home from './pages/Home.vue'
import ProductList from './pages/ProductList.vue'
import Profile from './pages/Profile.vue'
import ProductPage from './pages/ProductPage.vue'
import PaymentSuccessPage from './pages/PaymentSuccessPage.vue'
import PaymentFaliPage from './pages/PaymentFaliPage.vue'
import TextPage from './pages/TextPage.vue'
import Faq from './pages/Faq.vue'
import Verification from './pages/Verification.vue'
import WithdrawConfirm from './pages/WithdrawConfirm.vue'
import WithdrawFail from './pages/WithdrawFail.vue'
import WithdrawSuccess from './pages/WithdrawSuccess.vue'
import Escrow from './pages/Escrow.vue'
import Cart from './pages/Cart.vue'


Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history',
	routes: [
		//{ path: '/', redirect: '/home' },
		{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: '/cart',
			name: 'Cart',
			component: Cart
		},
		{
			path: '/product-list/:type',
			name: 'ProductList',
			component: ProductList
		},
		{
			path: '/profile/:page',
			name: 'Profile',
			component: Profile
		},
		{
			path: '/products/:id',
			name: 'ProductPage',
			component: ProductPage
		},
		{
			path: '/success-payment',
			name: 'PaymentSuccessPage',
			component: PaymentSuccessPage
		},
		{
			path: '/fail-payment',
			name: 'PaymentFaliPage',
			component: PaymentFaliPage
		},
		{
			path: '/faq',
			name: 'Faq',
			component: Faq
		},
		{
			path: '/verification',
			name: 'Verification',
			component: Verification
		},
		{
			path: '/pages/:id',
			name: 'TextPage',
			component: TextPage
		},
		{
			path: '/withdraw-confirm',
			name: 'WithdrawConfirm',
			component: WithdrawConfirm
		},
		{
			path: '/withdraw-fail',
			name: 'WithdrawFail',
			component: WithdrawFail
		},
		{
			path: '/withdraw-success',
			name: 'WithdrawSuccess',
			component: WithdrawSuccess
		},
		{
			path: '/escrow',
			name: 'Escrow',
			component: Escrow
		},
		{ 
			path: "*", 
			name: 'PageNotFound',
			component: PageNotFound,
			props: {title: "PageNotFound"}
		}
	]
})

