<template>
  <div class="modal withdraw-modal verify-modal">
    <div class="overlay" @click="$parent.closeVerifyModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeVerifyModal" src="./../assets/close.svg"/>
      <div class="container">
        <div class="form-wrapper">
          <div class="title small">{{ $t("Please verify first, to accept offer and receive withdrawal") }}</div>
          <div class="buttons">
            <button class="button" @click="$parent.closeVerifyModal">
              <span>{{ $t("Cancel") }}</span>
            </button>
            <router-link to="/verification" class="button blue">
              <span>{{ $t("Verify") }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VerifyModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      amount: '',
      error: ''
    }
  },
  methods: {
    submit() {
      let data = {'amount': this.amount}
      this.$http.post(process.env.VUE_APP_API + 'deposit/withdraw', data)
      .then((res) => {
          window.location = res.data.redirect_url;
      })
      .catch((res) => {
        this.error = res.response.data.message;
      })
    },
  }
}
</script>